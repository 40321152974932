import $ from 'jquery';

import './bootstrap3-typeahead';
import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';
import Alert from 'Components/Alert';
import { hide, show } from 'Components/domHelpers';
import { RefFormData, FormText, FormCheckbox, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';
import report from 'Error/report';

import ModalAlert from './ModalAlert';

import appErrorHandler from './appErrorHandler';
import s from './strings';
import errors from './errors';

export default class MakeCallModal {
  constructor(ctrl) {
    this.dialogOpenedGUID = null;
    this.requestSentGUID = null;

    this._ctrl = ctrl;
    this._enableSubconferences = this._ctrl.features.subConfs;

    this._form = new RefFormData();
    this._modal = new Modal({
      appendToBody: true,
      title: s.lblDialogTitleMakeCall,
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._send())}>
          <div class="modal-body">
            <Alert ref={this._alert} />
            <div class="control-label mb-2">{s.lblMakeCallText}</div>
            <div class="form-horizontal">
              <FormText form={this._form} name="phoneNumber" label={s.lblNumber} inputAttributes={{ autocomplete: 'off' }} />
              <FormText form={this._form} name="ext" label={s.lblExtension} />
              <FormText form={this._form} name="name" label={s.lblName} inputAttributes={{ autocomplete: 'off' }} />
              <FormText form={this._form} name="subConfID" label={s.lblSubConferenceID} />
              <FormCheckbox form={this._form} name="hostFlag" label={s.lblJoinAsHost} labelRight={s.lblUserHostNote} />
              <FormCheckbox form={this._form} name="dialExtension" label={s.lblDialExtension} />
              <FormCheckbox form={this._form} name="useDTMFConfirm" label={s.useDTMFConfirm} />

              <FormCheckbox form={this._form} name="addToAddressBook" label={s.lblAddToCallerList} />
            </div>

            <p>
              <strong>{s.lblMakeCallNoteLabel1}</strong> {s.lblMakeCallNoteText1}<br />
              <strong>{s.lblMakeCallNoteLabel2}</strong> {s.lblMakeCallNoteText2}
            </p>
            <p><strong>{s.lblMakeCallNoteLabel3}</strong> {s.lblMakeCallNoteText3}</p>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblOK}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });

    $(this._form.getInput('phoneNumber')).typeahead({
      minLength : 3,
      fitToElement : true,
      matcher: () => true,
      source : function(search, callback) {
        const params = {
          search,
          resultCount: 10,
        };

        Api.get('Bridge', 'getAddressBook', params)
          .then(res => {
            callback(res.addressBookEntry || []);
          })
          .catch(err => {
            callback([]);
          });
      },
      displayText : function(result) {
        return result.phoneNumber + ' ' + result.name;
      },
      afterSelect : result => {
        this._form.set('phoneNumber', result.phoneNumber);
        this._form.set('name', result.name);
      }
    });

    if (!this._enableSubconferences)
      this._form.setVisibility('subConfID', false);
  }

  _send() {
    this._modal.hide();

    const data = this._form.getAllValues();

    if (data.addToAddressBook && data.name.length < 1) {
      this.show(null, errors.ERR_INVALID_NAME);
      return;
    }

    Promise.resolve()
      .then(() => {
        if (data.addToAddressBook) {
          const params = {
            phoneNumber  : data.phoneNumber,
            name         : data.name,
            blockedFlag  : 0,
          };

          return Api.get('Bridge', 'setAddressBookEntry', params);
        }
      })
      .then(() => {
        if (this.dialogOpenedGUID === this.requestSentGUID) {
          report.send('makeCall error', null, new Error('DuplicateMakeCallDetected'));
          return;
        }

        this.requestSentGUID = this.dialogOpenedGUID;

        const params = {
          number          : data.phoneNumber,
          host            : data.hostFlag,
          name            : data.name,
          useDTMFConfirm  : data.useDTMFConfirm,
        };

        if (data.dialExtension)
          params.ext = data.ext;

        if (this._enableSubconferences)
          params.subConferenceID = data.subConfID;

        if (params.name.length < 1)
          delete params.name;

        return Api.get('LCM', 'makeCall', params);
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && [ 'phoneNumber', 'number' ].includes(err.parameterName)) {
            this.show(null, errors.ERR_INVALID_PHONE_NUMBER);
            return;
          }
        }

        const errorCode = appErrorHandler(err);
        this.displayError(errorCode);
      });
  }

  getGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  show(data, error) {
    if (!this._ctrl.makeCallAllowed) {
      this.displayError('ERR_MAKE_CALL_NOT_ALLOWED');
      return;
    }

    this.dialogOpenedGUID = this.getGUID();

    if (error) { // keep form as is and display error
      this._alert.textContent = error;
      show(this._alert);
    } else { // set up the form
      this._form.getAllFields().forEach(field => {
        this._form.set(field.name, data && data[field.name] || '');
      });

      this._form.set('subConfID', this._ctrl.subConfID || '');
      this._form.set('useDTMFConfirm', true);

      hide(this._alert);
    }

    this._modal.show();
  }

  displayError(errorCode) {
    ModalAlert.display(errorCode);
  }
}
